import SalidaTable from "./components/SalidaTable";
import { useEffect, useState } from "react";
import { convertMonth, getAllSalidas } from "./functions";
import { months } from "utils/apis";

import TabComponent from "components-base/tab/Tab";
import { useApplicationContext } from "components-base/application-context/AppContext";
import { Autocomplete, TextField } from "@mui/material";
const Salidas = () => {
  const years = [
    { id: 1, label: 2023 },
    { id: 2, label: 2024 },
    { id: 3, label: 2025 },
  ];
  const añoActual = new Date().getFullYear();
  const { globalFilter, setGlobalFilter } = useApplicationContext();
  const [salidas, setSalidas] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const data = years.filter((item) => item.label === añoActual)
  const [selectedOption, setSelectedOption] = useState(data[0]);

  useEffect(() => {
    if (globalFilter) {
      setSearchValue( globalFilter)
    }
    return () => {setSearchValue(null);setGlobalFilter(null)}
  }, [globalFilter])
  const handleSearch = (e) => {
    setSearchValue(e.target.value)
  }
  const clearSearch = () => {
    setSearchValue(null);
  }
  useEffect(() => {
    const fetchData = async () => {
      let salidas = await getAllSalidas();
      let _salidas = convertMonth(salidas);
      setSalidas(_salidas);
    };
    fetchData();
  }, [refresh]);

  useEffect(() => {
    if (salidas) {
      let tabsArray = months.map((month) => {
        let dataMonth = salidas.filter((item) => item.monthName === month);
        let dataYear = dataMonth.filter((item) => item.year === selectedOption.label);
        return {
          label: month,
          component: (
            <SalidaTable
              clearSearch={clearSearch}
              handleSearch={handleSearch}
              searchValue={searchValue}
              setRefresh={setRefresh}
              refresh={refresh}
              key={month}
              data={dataYear}
            />
          ),
        };
      });
      setTabs(tabsArray);
    }
  }, [salidas, searchValue, selectedOption]);

  const handleChange = (event, newValue) => {
    setSelectedOption(newValue);
  };

  return (
    <div>
      <Autocomplete
        disablePortal
        options={years}
        value={selectedOption}
        getOptionLabel={(option) => String(option.label)}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        size="small"
        sx={{ width: 150, left: 20 }}
        renderInput={(params) => <TextField {...params} label="Año" />}
      />
      <TabComponent tabsElement={tabs} />
    </div>
  );
};
export default Salidas;
