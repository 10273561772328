import { useEffect, useState } from "react";
import { convertMonth } from "components-base/salidas/functions";
import PedidosTable from "./components/PedidosTable";
import { getAllPedidos } from "./functions";
import { months } from "utils/apis";
import TabComponent from "components-base/tab/Tab";
import { Autocomplete, TextField } from "@mui/material";

const Pedidos = () => {
  const years = [
    { id: 1, label: 2023 },
    { id: 2, label: 2024 },
    { id: 3, label: 2025 },
  ];
  const añoActual = new Date().getFullYear();
  const [pedidos, setPedidos] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [refreshP, setRefreshP] = useState(false);
  const data = years.filter((item) => item.label === añoActual)
  const [selectedOption, setSelectedOption] = useState(data[0]);

  useEffect(() => {
    const fetchDataPedidos = async () => {
      let pedidos = await getAllPedidos();
      let _pedidos = await convertMonth(pedidos);
      setPedidos(_pedidos);
    };
    fetchDataPedidos();
  }, [refreshP]);

  useEffect(() => {
    if (pedidos) {
      let tabsArray = months.map((month) => {
        let dataMonth = pedidos.filter((item) => item.monthName === month);
        let dataYear = dataMonth.filter((item) => item.year === selectedOption.label);
        return {
          label: month,
          component: (
            <PedidosTable
              setRefresh={setRefreshP}
              refresh={refreshP}
              key={month}
              data={dataYear}
            />
          ),
        };
      });
      setTabs(tabsArray);
    }
  }, [pedidos, selectedOption]);

  const handleChange = (event, newValue) => {
    setSelectedOption(newValue);
  };

  return (
    <div>
      <Autocomplete
        disablePortal
        options={years}
        value={selectedOption}
        getOptionLabel={(option) => String(option.label)}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        size="small"
        sx={{ width: 150, left: 20 }}
        renderInput={(params) => <TextField {...params} label="Año" />}
      />
      <TabComponent tabsElement={tabs} />
    </div>
  );
};

export default Pedidos;
